import React from "react"
import { Link } from "gatsby"

export const BUTTON_CLASSES = "bg-white px-10 py-2 border border-solid border-black";

const Button = ({ text, to, external, otherClasses="" }) => {
    if (external) {
        return <a href={to} className={`no-underline ${BUTTON_CLASSES} ${otherClasses}`}>{text}</a>
    }

    return <Link className={`no-underline ${BUTTON_CLASSES} ${otherClasses}`} to={to}>{text}</Link>;
};

export default Button;
