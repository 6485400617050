import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../../../components/Layout"
import Seo from "../../../components/Seo"
import Hero from "../../../components/Hero"
import Button from "../../../components/Button";

import { imageContainer } from "./index.module.css"

const query = graphql`
  {
    books: allFile(
      filter: {
        sourceInstanceName: {eq: "libri"}
      }
      sort: {order: ASC, fields: childrenMarkdownRemark___frontmatter___title}
    ) {
      nodes {
        childMarkdownRemark {
          frontmatter {
            title
            author
            image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            slug
          }
        }
      }
    }
  }
`;

const mapNodeFrontmatter = (node) => node.childMarkdownRemark.frontmatter;

const LibreriaPage = () => {
  const data = useStaticQuery(query);
  const allBooks = data.books.nodes.map(mapNodeFrontmatter);

  return <Layout>
    <Seo
      title="Libreria"
      description="Se la nostra vita è una storia di cui noi siamo il protagonista, allora il cinema e la letteratura possono trasformarsi in un farmaco. Per questo motivo vi propongo alcuni spunti per trattare le nostre ferite interne"
    />
    <Hero title="Libreria" />
    <div className="grid grid-cols-6 py-6">
      <div className="col-span-6 md:col-start-2 md:col-span-4 px-5 md:p-0">
        <h2 className="uppercase py-4">Qualche spunto di lettura</h2>
        <section className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
          {allBooks.map(({ title, author, image, slug }, index) => {
            return <article key={index} className="col-span-1">
              <Link to={`/piccoli-sollievi/libreria/${slug}`}>
                <GatsbyImage
                  image={image.childImageSharp.gatsbyImageData}
                  alt={`${author} ${title}`}
                  className={`${imageContainer} rounded-lg`}
                />
              </Link>
            </article>
          })}
        </section>
        <div className="text-center mt-10">
          <Button text="Ritorna ai Piccoli Sollievi" to="/piccoli-sollievi" />
        </div>
      </div>
    </div>
  </Layout>;
};

export default LibreriaPage;
